// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    showVersion: true,
    testMode: true,
  
    publicKey:
      'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAq8SErhmhHyvjemUFpmSwRVvPl9AVrYdV1X5yUL/b6qbd+d8Glc09YCw4nEGoiKZ7C77OueY8cB2yKzqo7XgXWBEqSlspVWYG3jNn0Wc90+IOGTfWbYcadqSrdg2UJOBuGDbvzfJiEmfriwZlB6+fCthxwkGXkvU19SsWqln/amnT4IrHThmWc4r7YsCXwTd88pKO0gn98yKB0EHYFoqOM+W7UiBmUtoGiZJQgNdjeSTf117g+QbuGxQeMvWWBkY+qDTrB7HfcrPZJm/FX1kIFchXtcLZZ05YrY2PPRYF1TWgw+DHkSmyOe/+866GdlB8xnCocmhDecnBB0eVcimm4QIDAQAB',
  
    timeout: 120000,
    piBankWeb: 'https://www.pibank.es/',
    olimpiaNotificationsUrl: 'https://demorcs.olimpiait.com:6319',
    GTM_ID: 'GTM-53FRB6L',
    RECAPTCHA_KEY: '6Lfk3CEoAAAAAKpw9GnRcKWNpYbsLZvBTrwkmx0V',
  
    apiPath: 'https://apiuat.pruebasenlace.com/',
    version: {
      onboarding: '1.0.0',
      toolbox: '1.0.0',
      i18n: '1.0.0',
      auth: '1.0.0',
      cert: '1.0.0'
    }
  };
  